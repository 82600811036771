import React from "react";
import { Link } from "react-router-dom";
import About from "../../components/About/About";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Insights from "../../components/Insights/Insights";
import News from "../../components/News/News";
import Services from "../../components/Services/Services";
import ArrowIcon from "../../constant/Index";
import Banner from "./Banner";
import FeatureInsight from "./FeatureInsight";

const Home = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Banner Sec Start Here */}
			<Banner />
			{/* Banner Sec End Here */}

			{/* Service Sec Start Here */}
			<section className="service_sec">
				<div className="container">
					<div className="heading_wrapper">
						<h3>Learn more about what HAIsT can do for your Facility</h3>
					</div>
					<div className="row justify-content-center">
						<Services />
					</div>
				</div>
			</section>
			{/* Service Sec End Here */}

			{/* Insight Sec Start Here */}
			<section className="insight_sec">
				<div className="container">
					<div className="heading_wrapper both-sides-bar">
						<h3 className="title">Featured Insights</h3>
						<Link to="#" className="anchor">
							View All Expert Insights
							<ArrowIcon />
						</Link>
					</div>
					<div className="feature-insights">
						<FeatureInsight />
					</div>
					<div className="all-insights">
						<div className="row">
							<Insights />
						</div>
					</div>
				</div>
			</section>
			{/* Insight Sec End Here */}

			{/* About Sec Start Here */}
			<About />
			{/* About Sec End Here */}

			{/* News Sec Start Here */}
			<section className="news_sec">
				<div className="container">
					<div className="heading_wrapper both-sides-bar">
						<h3 className="title">News and Press Releases</h3>
						<Link to="#" className="anchor">
							View All Expert Insights
							<ArrowIcon />
						</Link>
					</div>
					<div className="row">
						<News />
					</div>
				</div>
			</section>
			{/* News Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Home;
