import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
	ServiceImg1,
	ServiceImg2,
	ServiceImg3,
	ServiceImg4,
	ServiceImg5,
} from "../../constant/Index";

const Services = () => {
	const location = useLocation();
	const currentlocation = location.pathname;

	const serviceData = [
		{
			id: 1,
			name: "Reduce Infections",
			image: ServiceImg1,
			description:
				"By tracking Symptoms, Haist allows you to identify trends before they spread, enabling you to reduce Infections across the entire facility.",
		},
		{
			id: 2,
			name: "Early Detection",
			image: ServiceImg2,
			description:
				"immediate reporting makes HAIsT your most valuable asset for the early detection of symptoms, allowing your staff to take immediate action.",
		},
		{
			id: 3,
			name: "Track Response Times",
			image: ServiceImg3,
			description:
				"By design, HAIsT reports in real time everything on the floor, from symptoms to assessments, you can see exactly how long it took each resident to get the attention they deserve.",
		},
		// {
		// 	id: 4,
		// 	name: "Compliance",
		// 	image: ServiceImg4,
		// 	description:
		// 		"Enabling organizations to ensure adherence with ever-changing regulatory obligations, manage risk, increase efficiency, and produce better business outcomes.",
		// },
		// {
		// 	id: 5,
		// 	name: "Legal",
		// 	image: ServiceImg5,
		// 	description:
		// 		"Serving legal professionals in law firms, General Counsel offices and corporate legal departments with data-driven decision-making tools. We streamline legal and regulatory research, analysis, and workflows to drive value to organizations, ensuring more transparent, just and safe societies.",
		// },
	];
	return (
		<>
			{serviceData?.map((data, index) => (
				<div className={currentlocation == "/" ? "col-lg-4" : ""} key={index}>
					<Link to="">
						<div className="service_box">
							<div className="img-box">
								<figure>
									<img
										src={data?.image}
										alt="service-img"
										className="img-fluid"
									/>
								</figure>
							</div>
							<div className="content-box">
								<h5 className="service-name">{data?.name}</h5>
								<p>{data?.description}</p>
								<div className="button-group">
									<Link to="#" className="secondary-btn">
										Learn More
										<i className="fa-solid fa-arrow-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</Link>
				</div>
			))}
		</>
	);
};

export default Services;
