import React from "react";
import "../../assets/css/results.css";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import News from "../../components/News/News";
import ArrowIcon, {
	caseStudyImg,
	ImporveImg1,
	ImporveImg2,
	ImporveImg3,
	InnerBanner3,
	InnerBannerImg2,
} from "../../constant/Index";

const Results = () => {
	const sepsisData = [
		{
			id: 1,
			name: "Conway Regional achieves sepsis program quality and financial goals",
			description:
				"With more visibility into patients’ status and timely and accurate alerts, the clinical team achieved strong SEP-1 compliance rates. Over four months, they improved SEP-1 bundle compliance by more than 20% and achieved cumulative savings of $384k.",
			btnText: "View Case Study",
			btnLink: "#",
		},
		{
			id: 2,
			name: "Blessing Health targets smarter sepsis surveillance HAist Sepsis Monitor",
			description:
				"“By combining advanced technology with our sepsis coordinator on the ground, we are better equipped to identify patients who develop sepsis earlier and optimize their care,” said Mary Barthel, MD, Chief Quality and Safety Officer, Blessing Health System.",
			btnText: "Read Press Release",
			btnLink: "#",
		},
		{
			id: 3,
			name: "JAMIA study shows a decrease in sepsis-related deaths",
			description:
				"This The Journal of the American Medical Informatics Association (JAMIA) study examined how clinicians at Alabama’s Huntsville Hospital decreased sepsis-related deaths by 53 percent during a 10-month period using a combination of clinical change management and electronic alerting from HAist Sepsis Monitor.",
			btnText: "View Study",
			btnLink: "#",
		},
		{
			id: 4,
			name: "Clinicians at Tift Regional Medical Center access sepsis monitoring alerts within EHR",
			description:
				"With the Cerner EHR integration, HAist Sepsis Monitor detects sepsis patients and, as doctors and nurses access the patient record, Sepsis Monitor displays alerts along with Tift’s sepsis order sets, which ensure evidence-based care is consistently delivered.",
			btnText: "Read Press Release",
			btnLink: "#",
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Breadcrumbs */}
			<Breadcrumbs currentPage="HAIsT Surveillance Results" />
			{/* Breadcrumbs */}

			{/* InnerBanner Start Here */}
			<InnerBanner
				heading="HAIsT Surveillance Results"
				description="HAIsT is the fastest grown early detection and assessment app in both Australia and New Zealand, We are transforming the way Aged Care reports and tracks symptoms, infections and outbreaks."
				btnText="Request a Demo"
				btnLink="/"
				bannerImage={InnerBanner3}
			/>
			{/* InnerBanner End Here */}

			{/* Secondary Header Start Here */}
			<SecondaryHeader />
			{/* Secondary Header End Here */}

			{/* Bottom Tagline Start Here */}
			{/* <section className="tagline">
				<div className="container">
					<div className="button-group">
						<Link to="#">
							<span className="bld">Best In KLAS 2022</span> - HAist #1 For
							Infection Control & Monitoring <ArrowIcon />
						</Link>
					</div>
				</div>
			</section> */}
			{/* Bottom Tagline End Here */}

			{/* Imporve Sec Start Here */}
			<section className="imporve-sec">
				<div className="container">
					<div className="heading-wrapper">
						<h2>
							With HAIsT Mangers access insights in custom reports and intuitive
							dashboards so they can improve resident outcomes, quality and
							Facility performance.
						</h2>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<div className="improve-box">
								<div className="icon-box">
									<figure>
										<img
											src={ImporveImg1}
											alt="improve-icon"
											className="img-fluid"
										/>
									</figure>
								</div>
								<div className="txt">
									<h4>HAIsT Residential Surveillance Solutions</h4>
									<p>
										With HAIsT, empower your HCW’s, RN’s and clinical managers
										to track every symptom effortlessly. See the response times,
										the patterns that have remained out of sight using our
										patent pending Smart View technology that looks for patterns
										in symptom assessment that goes well beyond the ability to
										simply create standardized assessments that come with every
										subscription to HAIsT
									</p>
									<div className="button-group">
										<Link to="#" className="primary-btn">
											24/7 Sepsis Monitoring <ArrowIcon />
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="improve-box">
								<div className="icon-box">
									<figure>
										<img
											src={ImporveImg2}
											alt="improve-icon"
											className="img-fluid"
										/>
									</figure>
								</div>
								<div className="txt">
									<h4>Identify HAI’s earlier</h4>
									<p>
										By using IPAC’s standard internationally accepted
										definitions, your staff can identify symptoms quickly and
										accurately.
									</p>
									<div className="button-group">
										<Link to="#" className="primary-btn">
											Explore Pharmacy <ArrowIcon />
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="improve-box">
								<div className="icon-box">
									<figure>
										<img
											src={ImporveImg3}
											alt="improve-icon"
											className="img-fluid"
										/>
									</figure>
								</div>
								<div className="txt">
									<h4>Real Time Symptoms Tracking</h4>
									<p>
										HAIsT gives you access to real time reporting, allowing you
										to see symptoms across an entire facility in seconds. With
										HAIsT you know the moment your HCW’s do.
									</p>
									<div className="button-group">
										<Link to="#" className="primary-btn">
											Explore Infection Prevention <ArrowIcon />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Imporve Sec End Here */}

			{/* Case Study Start Here */}
			<section className="case-study">
				<div className="container">
					<div className="case-study-box">
						<Link to="">
							<div className="row align-items-center">
								<div className="col-lg-6">
									<div className="img-box">
										<figure>
											<img
												src={caseStudyImg}
												alt="case-study-img"
												className="img-fluid"
											/>
										</figure>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="content-wrapper">
										<h3>
											HAIsT is changing the way Aged Care manages Residents.
											Join the Revolution!
										</h3>
										<p>
											Want to be part of our Case Studies? Apply now, we ask for
											12 months back infection data, void of private data to
											ensure you and we know, how much of a reduction in
											infections you have experienced using HAIsT.
										</p>
										<div className="button-group">
											<Link to="#" className="secondary-btn">
												Apply Now
												<ArrowIcon />
											</Link>
										</div>
									</div>
								</div>
							</div>
						</Link>
					</div>
					<div className="quoted">
						<h3>
							A core strategy to minimize the risk posed by widespread
							infectious diseases is the development of a surveillance system to
							quickly identify potential outbreaks as early as possible
							accurately, such that an effective intervention plan can be
							implemented before the substantial costs associated with the
							spread of diseases have been incurred.
						</h3>
						{/* <h4>
							Jan Bryant, RN, Infection Preventionist, National Park Medical
							Center
						</h4> */}
					</div>
				</div>
			</section>
			{/* Case Study End Here */}

			{/* Performace Sec Start Here */}
			<section className="performace_sec">
				<div className="container">
					<div className="heading-wrapper">
						<h2>The HAist Difference – Driving Results for our Customers</h2>
						<h3>Sepsis Performance</h3>
					</div>
					<div className="row">
						{sepsisData?.map((data, index) => (
							<div className="col-lg-3">
								<Link to="#" key={index}>
									<div className="sepsis-box">
										<h4 className="title">{data?.name}</h4>
										<p>{data?.description}</p>
										<div className="button-group">
											<Link to={data?.btnLink} className="secondary-btn">
												{data?.btnText}
												<ArrowIcon />
											</Link>
										</div>
									</div>
								</Link>
							</div>
						))}
					</div>
				</div>
			</section>
			{/* Performace Sec End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec">
				<div className="container">
					<div className="heading-wrapper">
						<h3>Latest Expert Insights</h3>
					</div>
					<div className="row">
						<News />
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Results;
