import React from "react";
import { Link } from "react-router-dom";
import ArrowIcon from "../../constant/Index";

const Banner = () => {
	return (
		<>
			{/* Banner Sec Start Here */}
			<section className="banner_sec">
				<div className="container">
					<div className="content-box">
						<h2 className="title">
							Reduce Infections, Track Response Times and Support Early
							Detection
						</h2>
						<p>
							HAIsT is the first app of it’s kind, empower your HCW’s to enter
							Symptoms that your RN’s can assess with standard internationally
							agreed Definitions to all common Aged Care Infections. With HAIsT,
							you know all your assessments meet the same criteria.
						</p>
						<div className="button-group">
							<Link to="#" className="primary-btn">
								Learn More
								<ArrowIcon />
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* Banner Sec End Here */}

			{/* Bottom Tagline Start Here */}
			{/* <section className="tagline">
				<div className="container">
					<div className="button-group">
						<Link to="#">
							<span className="bld">HAist</span> 2022 Nine-Month Trading Update{" "}
							<ArrowIcon />
						</Link>
					</div>
				</div>
			</section> */}
			{/* Bottom Tagline End Here */}
		</>
	);
};

export default Banner;
