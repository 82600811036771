import React from "react";

const DetailBanner = (props) => {
	const pageName = props.pageName;
	return (
		<>
			{/* Detail Banner Start Here */}
			<section className="detail_banner">
				<div className="container">
					<h2 className="page-title">{props?.pageName}</h2>
				</div>
			</section>
			{/* Detail Banner End Here */}
		</>
	);
};

export default DetailBanner;
