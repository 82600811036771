import React from "react";
import { useLocation } from "react-router-dom";
import {
	innovationImg1,
	innovationImg2,
	innovationImg3,
	innovationImg4,
	innovationImg5,
	innovationImg6,
} from "../../constant/Index";

const Innovations = () => {
	const location = useLocation();
	const currentlocation = location.pathname;

	const innovationData = [
		{
			id: 1,
			icon: innovationImg1,
			name: "Continuous surveillance of patient population",
			description:
				"HAist analyzes a broad cross-section of EHR data to understand each patient and alerts accordingly.",
		},
		{
			id: 2,
			icon: innovationImg2,
			name: "Sophisticated technology that identifies and alerts to at-risk patients",
			description:
				"Automated clinical surveillance of a hospital’s patient population to find patients early and accurately.",
		},
		{
			id: 3,
			icon: innovationImg3,
			name: "Delivers insights into customized workflows",
			description:
				"Alerts are routed directly into clinical workflows equipping care teams to act early while adhering to hospital protocols.",
		},
		{
			id: 4,
			icon: innovationImg4,
			name: "Intuitive analysis dashboards",
			description:
				"HAist’s analytics dashboards provide a comprehensive view of program performance to drive impactful improvements.",
		},
		{
			id: 5,
			icon: innovationImg5,
			name: "Dedication to your success",
			description:
				"Access a team of HAist professionals committed to your success including informatics teams, implementation teams, a dedicated Customer Success Manager, and 24/7 technical support.",
		},
		{
			id: 6,
			icon: innovationImg6,
			name: "Consistency across operations",
			description:
				"Empower your team with centralized management, integration with a single platform, and ease of scalability and standardization to minimize IT effort.",
		},
	];
	return (
		<>
			{innovationData?.map((data, index) => (
				<div
					className={
						currentlocation == "/haist-clinical-surveillance"
							? "col-lg-4"
							: ""
					}
					key={index}
				>
					<div className="innovation-box">
						<div className="icon-box">
							<figure>
								<img
									src={data?.icon}
									alt="innovation-img"
									className="img-fluid"
								/>
							</figure>
						</div>
						<div className="content-box">
							<h4>{data?.name}</h4>
							<p>{data?.description}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default Innovations;
