import React from "react";
import { Link } from "react-router-dom";
import News from "../News/News";
import ArrowIcon from "../../constant/Index";

const QueryCards = (props) => {
	const Icon = props.Icon;
	const Type = props.Type;
	const Image = props.Image;
	const Category = props.Category;
	const Date = props.Date;
	const Name = props.Name;
	const Description = props.Description;
	return (
		<>
			{/* Query Card Start Here */}
			<div className="query-card">
				<Link to="#">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="query-image">
								<div className="tag">
									<h6>
										{props?.Icon} {props?.Type}
									</h6>
								</div>
								<figure>
									<img
										src={props?.Image}
										alt="query-img"
										className="img-fluid"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="content-wrapper">
								<h5>
									<span className="category">{props?.Category}</span>
									<p>{props?.Date}</p>
								</h5>
								<h2>{props?.Name}</h2>
								<p>{props?.Description}</p>
								<div className="button-group">
									<Link className="secondary-btn" to="#">
										Learn More <ArrowIcon />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</Link>
			</div>
			{/* Query Card End Here */}
		</>
	);
};

export default QueryCards;
