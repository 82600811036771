import React from "react";
import "../../assets/css/support.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DetailBanner from "../../components/DetailBanner/DetailBanner";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import { SupportImage } from "../../constant/Index";

const Support = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Bread Crumbs Start Here */}
			<Breadcrumbs currentPage="Technical Support" />
			{/* Bread Crumbs End Here */}

			{/* Detailed Banner Start Here */}
			<DetailBanner pageName="Technical Support" />
			{/* Detailed Banner End Here */}

			{/* Secondary Header Start Here */}
			<SecondaryHeader />
			{/* Secondary Header End Here */}

			{/* Support Sec Start Here */}
			<section className="support-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="img-box">
								<figure>
									<img
										src={SupportImage}
										alt="support-images"
										className="img-fluid"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="support-form">
								<div className="title">
									<h2>
										Contact Technical Support By Completing The Form Below
									</h2>
								</div>
								<form action="">
									<div className="form-group">
										<label>Contact Name</label>
										<input type="text" className="form-control" />
									</div>
									<div className="form-group">
										<label>Company</label>
										<input type="text" className="form-control" />
									</div>
									<div className="form-group">
										<label>Product:</label>
										<select className="form-control">
											<option>--None--</option>
											<option value="Critical Point">Critical Point</option>
											<option value="Interfaces">Interfaces</option>
											<option value="Non Product Related">
												Non Product Related
											</option>
											<option value="Quantifi">Quantifi</option>
											<option value="HAist IP">HAist IP</option>
											<option value="HAist RPH">HAist RPH</option>
											<option value="Simplifi 797">Simplifi 797</option>
											<option value="VeriForm">VeriForm</option>
											<option value="POCA">POCA</option>
											<option value="Analytics">Analytics</option>
											<option value="Simplifi 797 on SoleSource">
												Simplifi 797 on SoleSource
											</option>
											<option value="Simplifi+ MedStorage">
												Simplifi+ MedStorage
											</option>
										</select>
									</div>
									<div className="form-group">
										<label>Email</label>
										<input type="email" className="form-control" />
									</div>
									<div className="form-group">
										<label>Phone</label>
										<input type="tel" className="form-control" />
									</div>
									<div className="form-group">
										<label>Provided Site ID:</label>
										<input type="text" className="form-control" />
									</div>
									<div className="form-group">
										<label>Subject</label>
										<input type="text" className="form-control" />
									</div>
									<div className="form-group">
										<label>Description</label>
										<textarea className="form-control"></textarea>
									</div>
									<div className="button-group">
										<button className="primary-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Support Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Support;
