import React from "react";
import "../../assets/css/auth.css";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DetailBanner from "../../components/DetailBanner/DetailBanner";
import Header from "../../components/Header/Header";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import ArrowIcon, { SoleImage } from "../../constant/Index";
import Footer from "../../components/Footer/Footer";
import CtaCards from "../../components/CtaCards/CtaCards";

const CustomerLogin = () => {
	const otherLogin = [
		{
			id: 1,
			name: "Simplifi 797® Login",
			description: "Access to the application not on SoleSource.",
			btnText: "Login",
		},
		{
			id: 2,
			name: "CriticalPoint® Login",
			description: "Pharmacy Compliance Education partner.",
			btnText: "Login",
		},
		{
			id: 3,
			name: "StaffReady® Login",
			description: "Workforce management software partner.",
			btnText: "Login",
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Bread Crumbs Start Here */}
			<Breadcrumbs currentPage="Clinical Surveillance and Compliance Customer Login" />
			{/* Bread Crumbs End Here */}

			{/* Detailed Banner Start Here */}
			<DetailBanner pageName="Clinical Surveillance and Compliance Customer Login" />
			{/* Detailed Banner End Here */}

			{/* Secondary Header Start Here */}
			<SecondaryHeader />
			{/* Secondary Header End Here */}

			{/* Bottom Tagline Start Here */}
			<section className="tagline with-background">
				<div className="container">
					<div className="button-group">
						<Link to="#">
							<span className="bld">Best in KLAS 2022 </span> - HAist #1 for
							Infection Control & Monitoring <ArrowIcon />
						</Link>
					</div>
				</div>
			</section>
			{/* Bottom Tagline End Here */}

			{/* Note Sec Start Here */}
			<section className="note_sec">
				<div className="container">
					<h2>
						Sign-in to your HAist application or partner solution using the
						links below.
					</h2>
					<h4>
						If you have any questions or need technical support, click the
						'Technical Support' link above to submit a ticket. You may also call
						our support number at (800) 341-0450 or email &nbsp;
						<a href="mailto:example@example.com">example@example.com</a>
					</h4>
				</div>
			</section>
			{/* Note Sec End Here */}

			{/* Sole Sec Start Here */}
			<section className="sole_sec">
				<div className="container">
					<div className="sole-box">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<div className="img-box">
									<figure>
										<img
											src={SoleImage}
											alt="sole-image"
											className="img-fluid"
										/>
									</figure>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="content-wrapper">
									<h3>SoleSource Account</h3>
									<p>
										Use this link to access the following applications:
										<br />
										HAist Infection Prevention | HAist Pharmacy | HAist Sepsis
										Monitor | Simplifi 797 | Simplifi+ MedStorage | Quantifi |
										VeriForm
									</p>
									<div className="button-group">
										<Link to="#" className="secondary-btn">
											Login <ArrowIcon />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Sole Sec End Here */}

			{/* Other Login Sec Start Here */}
			<section className="other-login">
				<div className="container">
					<div className="title">
						<h3>Other Logins: </h3>
					</div>
					<div className="row">
						<CtaCards cardData={otherLogin} />
					</div>
				</div>
			</section>

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default CustomerLogin;
