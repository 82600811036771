import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CtaCards from "../../components/CtaCards/CtaCards";
import DetailBanner from "../../components/DetailBanner/DetailBanner";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import News from "../../components/News/News";

const Sepsis = () => {
	const CtaData = [
		{
			id: 1,
			name: "Sepsis Performance Resources",
			description:
				"Access the library of sepsis performance articles, case studies, webinars, press releases, and more.",
			btnText: "View All Assets",
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* BreadCrumbs Start Here */}
			<Breadcrumbs currentPage="Sepsis Performance Resources" />
			{/* BreadCrumbs End Here */}

			{/* Detail Banner Start Here */}
			<DetailBanner pageName="Sepsis Performance Resources" />
			{/* Detail Banner End Here */}

			{/* Secondary Banner Start Here */}
			<SecondaryHeader />
			{/* Secondary Banner End Here */}

			{/* Blog Sec Start Here */}
			<section className="news_sec">
				<div className="container">
					<div className="row">
						<News />
						<News />
						<News />
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Cta Card Start Here */}
			<section className="cta-cards-sec">
				<div className="container">
					<div className="row">
						<CtaCards cardData={CtaData} />
					</div>
				</div>
			</section>
			{/* Cta Card End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Sepsis;
