import React from "react";
import { Link } from "react-router-dom";
import ArrowIcon from "../../constant/Index";

const CtaCards = (props) => {
	const cardData = props.cardData;
	return (
		<>
			{/* CTA Cards Start Here */}
			{cardData?.map((data, index) => (
				<div className="col-lg-4">
					<Link to="#">
						<div className="cta-cards" key={index}>
							<h4 className="title">{data?.name}</h4>
							<p>{data?.description}</p>
							<div className="button-group">
								<Link to="#" className="secondary-btn">
									{data?.btnText} <ArrowIcon />
								</Link>
							</div>
						</div>
					</Link>
				</div>
			))}
			{/* CTA Cards End Here */}
		</>
	);
};

export default CtaCards;
