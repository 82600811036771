import React from "react";
import { Link } from "react-router-dom";
import { InnerBannerImg } from "../../constant/Index";

const InnerBanner = (props) => {
	const heading = props.heading;
	const description = props.description;
	const btnText = props.btnText;
	const btnLink = props.btnLink;
	const bannerImage = props.bannerImage;

	return (
		<>
			{/* Inner Banner Start Here */}
			<section className="inner-banner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="content-wrapper">
								<h3 className="title">{heading}</h3>
								<p>{description}</p>
								<div className="button-group">
									<Link className="primary-btn" to={btnLink}>
										{btnText}
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="img-box">
								<figure>
									<img
										src={bannerImage}
										alt="inner-banner-image"
										className="img-fluid"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Inner Banner End Here */}
		</>
	);
};

export default InnerBanner;
