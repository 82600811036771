import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SecondaryHeader = () => {
	const location = useLocation();
	const currentlocation = location.pathname;
	const [scroll, setScroll] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 550);
		});
	}, []);
	return (
		<>
			<div className={scroll ? "bottom-header fixed-header" : "bottom-header"}>
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<ul className="left-menus menus">
								<li
									className={
										currentlocation == "/haist-clinical-surveillance"
											? "menu-items active"
											: "menu-items"
									}
								>
									<Link
										to="/haist-clinical-surveillance"
										className="menu-link"
									>
										HAist
									</Link>
								</li>
								<li
									className={
										currentlocation == "/results"
											? "menu-items active"
											: "menu-items"
									}
								>
									<Link to="/results" className="menu-link">
										Results
									</Link>
								</li>
								<li className="menu-items dropdown-li">
									<button
										className="menu-link dropdown-toggle"
										type="button"
										id="dropdownMenuButton1"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										Resources
										<i class="fa-solid fa-angle-down"></i>
									</button>
									<div
										className="dropdown-menu"
										aria-labelledby="dropdownMenuButton1"
									>
										<div className="title">
											<h5>HAist Resources</h5>
										</div>
										<div className="row">
											<div className="col-lg-3 col-md-4">
												<div className="content">
													<p>
														Our resource center provides clinical information
														curated and developed by our infectious
														preventionists, epidemiologists, and pharmacists.
													</p>
												</div>
											</div>
											<div className="col-lg-3 col-md-4">
												<ul className="inner-menus">
													<li className="menu-items">
														<Link to="/infection">
															Infection Prevention Resources
														</Link>
													</li>
													<li className="menu-items">
														<Link to="/pharmacy">
															Pharmacy Stewardship Resources
														</Link>
													</li>
													<li className="menu-items">
														<Link to="/sepsis">
															Sepsis Performance Resources
														</Link>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</li>
								<li
									className={
										currentlocation == "/about-us"
											? "menu-items active"
											: "menu-items"
									}
								>
									<Link to="/about-us" className="menu-link">
										About Us
									</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-5">
							<ul className="right-menus menus">
								<li
									className={
										currentlocation == "/technical-support"
											? "menu-items active"
											: "menu-items"
									}
								>
									<Link to="/technical-support" className="menu-link">
										Tech support
									</Link>
								</li>
								<li
									className={
										currentlocation == "/customer-login"
											? "menu-items active"
											: "menu-items"
									}
								>
									<Link to="/customer-login" className="menu-link">
										Customer Login
									</Link>
								</li>
								<li className="menu-items">
									<Link to="#" className="menu-link request-btn">
										Request Demo
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SecondaryHeader;
