import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../constant/Index";
import "../../assets/css/style.css";
import "../../assets/css/variable.css";
import Modals from "../AllModals/Modals";

const Header = () => {
	const [visible, setVisible] = useState(false);
	const [search, setSearch] = useState(false);
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
			 in place of 'smooth' */
		});
	};

	window.addEventListener("scroll", toggleVisible);
	return (
		<>
			<Modals />
			{/* Header Start Here */}
			<div className="secondary-header" id="goTop">
				<div className="container">
					<div className="main-header">
						<div className="left-side">
							<Link to="/about-us" className="dropdown">
								About HAist
							</Link>
						</div>
						<div className="right-side">
							<ul className="list">
								<li>
									<Link to="/about-us">About Us</Link>
								</li>
								<li>
									<Link to="#">CAREERS</Link>
								</li>
								<li>
									<Link to="#">INVESTORS</Link>
								</li>
								<li>
									<button
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#locationModal"
									>
										<i className="fa-solid fa-globe"></i>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<header>
				<div className="container">
					<div className="topbar">
						<div className="logo-box">
							<Link to="/">
								<figure>
									<img src={Logo} alt="web-logo" />
								</figure>
							</Link>
						</div>
						<div className={search ? "search-box show" : "search-box"}>
							<form action="">
								<div className="form-group">
									<input
										onClick={() => setSearch(true)}
										type="text"
										placeholder="Search"
										className="form-control"
									/>
								</div>
								<div className="button-group">
									<button className="btn-submit">
										<i className="fa-solid fa-magnifying-glass"></i>
									</button>
									<button
										className="cross"
										type="button"
										onClick={() => setSearch(false)}
									>
										<i className="fa-solid fa-xmark"></i>
									</button>
								</div>
							</form>
						</div>
					</div>
					<div className="menus-bar">
						<ul className="menu-lists">
							{/* <li className="menu-items">
								<Link
									to="/sentri7-clinical-surveillance"
									className="menu-links"
								>
									HAist
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Tax & Accounting
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Finance
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Compliance
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Legal
								</Link>
							</li> */}
							<li className="menu-items">
								<Link
									to="/haist-clinical-surveillance"
									className="menu-links"
								>
									HAIST App
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Consistent Assessments
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Reducing AMR
								</Link>
							</li>
							<li className="menu-items">
								<Link to="#" className="menu-links">
									Real Time Data
								</Link>
							</li>
							{/* <li className="menu-items">
								<Link to="#" className="menu-links">
									Legal
								</Link>
							</li> */}
						</ul>
					</div>
				</div>
			</header>
			{/* Header End Here */}

			{/* Go To Top */}
			<div className={visible ? "go_top active" : "go_top"}>
				<button onClick={scrollToTop}>
					<i class="fa-solid fa-arrow-up"></i>
				</button>
			</div>
			{/* Go To Top */}
		</>
	);
};

export default Header;
