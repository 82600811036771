import React from "react";
import { Link } from "react-router-dom";
import ArrowIcon, { InsightImg1 } from "../../constant/Index";

const FeatureInsight = () => {
	const featureInsight = [
		{
			id: 1,
			image: InsightImg1,
			name: "What internal audit should know about ESG risks: E is for Environmental",
			description:
				"In this article, we will drill down on Environmental risk and explore how internal audit can have an impact by focusing on key risks.",
		},
	];
	return (
		<>
			<div className="feature-insight-box">
				<div className="img-box">
					<figure>
						<img
							src={featureInsight[0]?.image}
							alt="feature-insight-img"
							className="img-fluid"
						/>
					</figure>
				</div>
				<div className="content-box">
					<h3 className="insight-name">{featureInsight[0]?.name}</h3>
					<p>{featureInsight[0]?.description}</p>
					<div className="button-group">
						<Link to="#" className="secondary-btn">
							Read More
							<ArrowIcon />
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default FeatureInsight;
