import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
	const currentPage = props.currentPage;
	return (
		<>
			{/* Breadcrumbs Start Here */}
			<div className="breadcrumbs">
				<div className="container">
					<ul className="bread-crumbs">
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>{currentPage}</li>
					</ul>
				</div>
			</div>
			{/* Breadcrumbs End Here */}
		</>
	);
};

export default Breadcrumbs;
