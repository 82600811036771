import React from "react";
import "../../assets/css/infection.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CtaCards from "../../components/CtaCards/CtaCards";
import DetailBanner from "../../components/DetailBanner/DetailBanner";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import News from "../../components/News/News";
import { QueryImg1, QueryImg2 } from "../../constant/Index";
import QueryCards from "../../components/QueryBox/QueryCards";

const Infection = () => {
	const CtaData = [
		{
			id: 1,
			name: "Infection Prevention Resources",
			description:
				"Access the library of infection prevention articles, case studies, webinars, press releases, and more.",
			btnText: "View All Assets",
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* BreadCrumbs Start Here */}
			<Breadcrumbs currentPage="Infection Prevention Resources" />
			{/* BreadCrumbs End Here */}

			{/* Detail Banner Start Here */}
			<DetailBanner pageName="Infection Prevention Resources" />
			{/* Detail Banner End Here */}

			{/* Secondary Banner Start Here */}
			<SecondaryHeader />
			{/* Secondary Banner End Here */}

			{/* Query Section Start Here */}
			<section className="query-sec">
				<div className="container">
					<QueryCards
						Icon={<i class="fa-solid fa-book"></i>}
						Type="Case Study"
						Image={QueryImg1}
						Category="Health"
						Date="February 22, 2022"
						Name="Identifying at-risk patients for sepsis, HAIs, and readmission"
						Description="Real-time clinical surveillance goes beyond electronic health record (EHR) alerts to identify the early onset of patient deterioration or life-threatening events requiring care team intervention."
					/>
				</div>
			</section>
			{/* Query Section End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec">
				<div className="container">
					<div className="row">
						<News />
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Query Section Start Here */}
			<section className="query-sec">
				<div className="container">
					<QueryCards
						Icon={<i class="fa-solid fa-video"></i>}
						Type="WEBINAR"
						Image={QueryImg2}
						Category="Health"
						Date="February 22, 2022"
						Name="How to build a high performing infection prevention control program"
						Description="Hospitals need high-performing and resilient IPC programs to keep patients and personnel safe, comply with public reporting, battle MDROs, promote AMS efforts and improve outcomes."
					/>
				</div>
			</section>
			{/* Query Section End Here */}

			{/* Cta Card Start Here */}
			<section className="cta-cards-sec">
				<div className="container">
					<div className="row">
						<CtaCards cardData={CtaData} />
					</div>
				</div>
			</section>
			{/* Cta Card End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Infection;
