import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import About from "../pages/About/About";
import CustomerLogin from "../pages/Auth/CustomerLogin";
import Home from "../pages/Home/Home";
import Infection from "../pages/Resources/Infection";
import Pharmacy from "../pages/Resources/Pharmacy";
import Sepsis from "../pages/Resources/Sepsis";
import Results from "../pages/Results/Results";
import Sentri7 from "../pages/Sentri7/Sentri7";
import Support from "../pages/Support/Support";

const PublicRoutes = () => {
	return (
		<HashRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/haist-clinical-surveillance" element={<Sentri7 />} />
				<Route path="/results" element={<Results />} />
				<Route path="/infection" element={<Infection />} />
				<Route path="/pharmacy" element={<Pharmacy />} />
				<Route path="/sepsis" element={<Sepsis />} />
				<Route path="/about-us" element={<About />} />
				<Route path="/technical-support" element={<Support />} />
				<Route path="/customer-login" element={<CustomerLogin />} />
			</Routes>
		</HashRouter>
	);
};

export default PublicRoutes;
