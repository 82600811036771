import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../constant/Index";

const Modals = () => {
	const VideoRef = useRef();
	console.log(VideoRef);

	const handlePlay = () => {
		VideoRef.pause();
	};
	return (
		<>
			{/* Location Modal Start Here */}
			<div
				className="modal fade"
				id="locationModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="locationModalLabel"
				aria-hidden="true"
			>
				<div className="close">
					<button
						type="button"
						className="close-btn"
						data-bs-dismiss="modal"
						aria-label="Close"
					>
						<svg
							width="30"
							height="30"
							fill="#007ac3"
							className="bi bi-x"
							viewBox="0 0 16 16"
						>
							<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
						</svg>
					</button>
				</div>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-body">
							<div className="logo-box">
								<figure>
									<img src={Logo} alt="logo" className="img-fluid" />
								</figure>
							</div>
							<div className="tagline">
								<h4>Visit our global site, or select a location</h4>
							</div>
							<ul className="nav nav-tabs" id="myTab" role="tablist">
								<li className="nav-item" role="presentation">
									<button
										className="nav-link active"
										id="america-tab"
										data-bs-toggle="tab"
										data-bs-target="#america"
										type="button"
										role="tab"
										aria-controls="america"
										aria-selected="true"
									>
										Americas
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link"
										id="europe-tab"
										data-bs-toggle="tab"
										data-bs-target="#europe"
										type="button"
										role="tab"
										aria-controls="europe"
										aria-selected="false"
									>
										Europe
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link"
										id="pacific-tab"
										data-bs-toggle="tab"
										data-bs-target="#pacific"
										type="button"
										role="tab"
										aria-controls="pacific"
										aria-selected="false"
									>
										Asia & Pacific
									</button>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div
									className="tab-pane fade show active"
									id="america"
									role="tabpanel"
									aria-labelledby="america-tab"
								>
									<div className="row all-locations">
										<div className="col-lg-3 col-md-6">
											<ul>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="europe"
									role="tabpanel"
									aria-labelledby="europe-tab"
								>
									<div className="row all-locations">
										<div className="col-lg-3 col-md-6">
											<ul>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="pacific"
									role="tabpanel"
									aria-labelledby="pacific-tab"
								>
									<div className="row all-locations">
										<div className="col-lg-3 col-md-6">
											<ul>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
												<li>
													<h4 className="country-name">Brazil</h4>
													<h5 className="link">
														<span>Home page:</span>
														<Link to="#">Portuguese</Link>
													</h5>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Location Modal End Here */}

			{/* Video Modal Start Her */}
			<div
				class="modal fade"
				id="videoModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="videoModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<iframe
								VideoRef
								width="100%"
								height="540"
								src="https://www.youtube.com/embed/BuOrvgRbDzQ"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen
							></iframe>
						</div>
					</div>
				</div>
			</div>
			{/* Video Modal End Here */}
		</>
	);
};

export default Modals;
