import React from "react";
import "../../assets/css/sentri.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { InnerBanner3, InnerBannerImg } from "../../constant/Index";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import Solution from "../../components/Solution/Solution";
import Innovations from "../../components/Innovations/Innovations";
import News from "../../components/News/News";
import DemoForm from "../../components/DemoForm/DemoForm";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const Sentri7 = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Breadcrumbs */}
			<Breadcrumbs currentPage="HAist® Clinical Surveillance" />
			{/* Breadcrumbs */}

			{/* InnerBanner Start Here */}
			<InnerBanner
				heading="HAIsT Surveillance Results "
				description="HAIsT is the fastest grown early detection and assessment app in both Australia and New Zealand, We are transforming the way Aged Care reports and tracks symptoms, infections and outbreaks."
				btnText="Request a Demo"
				btnLink="/"
				bannerImage={InnerBanner3}
			/>
			{/* InnerBanner End Here */}

			{/* Secondary Header Start Here */}
			<SecondaryHeader />
			{/* Secondary Header End Here */}

			{/* Solution Sec Start Here */}
			<section className="solution_sec">
				<div className="container">
					<div className="heading-wrapper">
						<h2 className="title">
							{/* Choose a trusted suite of clinical surveillance solutions — on a
							single platform — to provide an early line of sight into emerging
							clinical concerns. */}
							With HAIsT you have a trusted suite of surveillance solutions
							specifically designed for Aged Care. Providing early diagnosis,
							reliable, consistent assessments and resident tracking.
						</h2>
					</div>
					<div className="solution-wrapper">
						<div className="heading-wrapper">
							{/* <h2 className="title">HAist Clinical Surveillance solutions</h2> */}
							<h2 className="title">
								HAIsT Residential Surveillance Solutions
							</h2>
							<p>
								{/* HAist clinical surveillance aggregates EHR data to find
								patients early and accurately and informs busy care teams of
								intervention opportunities to improve patient care. HAist
								makes it easy to implement, manage, and standardize clinical
								programs across facilities. We know it's difficult to balance
								caring for patients with managing regulatory, quality, and
								financial challenges. We continue to add capabilities to our
								clinical surveillance suite to maximize value for our customers.
								We help hospitals like yours, solve the following challenges: */}
								With HAIsT, empower your HCW’s, RN’s and clinical managers to
								track every symptom effortlessly. See the response times, the
								patterns that have remained out of sight using our patent
								pending Smart View technology that looks for patterns in symptom
								assessment that goes well beyond the ability to simply create
								standardized assessments that come with every subscription to
								HAIsT
							</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<Solution />
					</div>
				</div>
			</section>
			{/* Solution Sec End Here */}

			{/* Innovation Sec Start Here */}
			<section className="innovation_sec">
				<div className="container">
					<div className="heading-wrapper">
						<h2>The HAist Clinical Surveillance difference</h2>
						<p>
							HAist Clinical Surveillance brings innovation, clinical expertise,
							and dedicated customer support of HAist to your organization.
						</p>
					</div>
					<div className="row justify-content-center">
						<Innovations />
					</div>
				</div>
			</section>
			{/* Innovation Sec End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec">
				<div className="container">
					<div className="heading-wrapper">
						<h2>HAist Clinical Surveillance expert insights</h2>
					</div>
					<div className="row">
						<News />
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Demo Sec Start Here */}
			<DemoForm />
			{/* Demo Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Sentri7;
