import React from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowIcon, {
	InsightImg2,
	InsightImg3,
	InsightImg4,
} from "../../constant/Index";

const Insights = () => {
	const location = useLocation();
	const currentlocation = location.pathname;
	const insightsData = [
		{
			id: 1,
			image: InsightImg2,
			name: "Healthcare mega-mergers drive need for nursing policy and procedure standardization",
		},
		{
			id: 2,
			image: InsightImg3,
			name: "APIs are the lifeblood of software – and can be for your firm",
		},
		{
			id: 3,
			image: InsightImg4,
			name: "TeamMate Audit Benchmark: Responding to change",
		},
	];
	return (
		<>
			{insightsData?.map((data, index) => (
				<div className={currentlocation == "/" ? "col-lg-4" : ""} key={index}>
					<div className="insight-box">
						<div className="img-box">
							<figure>
								<img
									src={data?.image}
									alt="insights-img"
									className="img-fluid"
								/>
							</figure>
						</div>
						<div className="content-box">
							<h3 className="insight-name">{data?.name}</h3>
							<div className="button-group">
								<Link to="#" className="secondary-btn">
									Read More
									<ArrowIcon />
								</Link>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default Insights;
